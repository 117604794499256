/*eslint-disable react/prop-types*/
import React from "react";
import { MaterialReactTable } from "material-react-table";

import { useTheme, lighten, darken } from "@mui/material/styles";

import { useMaterialUIController } from "context";

const CustomMaterialReactTable = ({
  columns,
  data,
  enableStickyHeader = true,
  enableKeyboardShortcuts = false,
  ...props
}) => {
  const [controller] = useMaterialUIController();
  const theme = useTheme();
  const { darkMode } = controller;

  const EVEN_ROW_THEME = darkMode
    ? lighten(theme.palette.background.sidenav, 0.05)
    : lighten(theme.palette.primary.main, 0.9);
  const ODD_ROW_THEME = darkMode
    ? darken(theme.palette.primary.main, 0.65)
    : theme.palette.white.main;
  const ROW_HOVER_THEME = darkMode
    ? darken(theme.palette.primary.main, 0.5)
    : lighten(theme.palette.primary.main, 0.8);
  const ROW_SELECTED_THEME = darkMode
    ? darken(theme.palette.primary.main, 0.5)
    : lighten(theme.palette.primary.main, 0.85);

  const defaultPositionGlobalFilter = props.positionGlobalFilter
    ? props.positionGlobalFilter
    : "right";

  const defaultPositionToolbarAlertBanner = props.positionToolbarAlertBanner
    ? props.positionToolbarAlertBanner
    : "top";

  const defaultPositionPagination = props.positionPagination ? props.positionPagination : "bottom";

  const defaultMuiLinearProgressProps = (isTopToolbar) => {
    if (props.muiLinearProgressProps) return props.muiLinearProgressProps;
    else {
      return {
        color: "primaryLight",
        sx: {
          display: isTopToolbar ? "block" : "none", // hide bottom progress bar
        },
      };
    }
  };

  const defaultMuiTableContainerProps = props.muiTableContainerProps
    ? props.muiTableContainerProps
    : {
        sx: {
          minHeight: "350px !important",
          maxHeight: `calc(100vh - 179px - 250px)`,
          borderRadius: "0",
        },
      };

  const defaultMuiToolbarAlertBannerChipProps = props.muiToolbarAlertBannerChipProps
    ? props.muiToolbarAlertBannerChipProps
    : { color: "primary" };

  const defaultMuiSearchTextFieldProps = props.muiSearchTextFieldProps
    ? props.muiSearchTextFieldProps
    : { placeholder: "Search", sx: { minWidth: "200px" } };

  const defaultMuiTableBodyProps = props.muiTableBodyProps
    ? props.muiTableBodyProps
    : {
        sx: {
          "& tr:nth-of-type(even)": {
            backgroundColor: EVEN_ROW_THEME,
            "&:hover, &.Mui-hovered": {
              backgroundColor: ROW_HOVER_THEME,
            },
            "&.Mui-selected": {
              backgroundColor: ROW_SELECTED_THEME,
              "&:hover, &.Mui-hovered": {
                backgroundColor: ROW_HOVER_THEME,
              },
            },
          },
          "& tr:nth-of-type(odd)": {
            backgroundColor: ODD_ROW_THEME,
            "&:hover, &.Mui-hovered": {
              backgroundColor: ROW_HOVER_THEME,
            },
            "&.Mui-selected": {
              backgroundColor: ROW_SELECTED_THEME,
              "&:hover, &.Mui-hovered": {
                backgroundColor: ROW_HOVER_THEME,
              },
            },
          },
        },
      };

  const defaultMuiTableHeadProps = props.muiTableHeadProps
    ? props.muiTableHeadProps
    : { sx: { padding: "0px" } };

  const defaultMuiSelectCheckboxProps = props.muiSelectCheckboxProps
    ? props.muiSelectCheckboxProps
    : { color: darkMode ? "white" : "secondary" };

  const defaultMuiDetailPanelProps = props.muiDetailPanelProps
    ? props.muiDetailPanelProps
    : {
        sx: {
          backgroundColor: darkMode ? theme.palette.background.default : theme.palette.light.main,
        },
      };

  const defaultDisplayColumnDefOptions = props.displayColumnDefOptions
    ? props.displayColumnDefOptions
    : {
        "mrt-row-actions": {
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
            sx: {
              "& .MuiBox-root": {
                display: "flex",
                gap: "0.5rem",
              },
              "& .MuiSvgIcon-root": {
                height: "20px !important",
                width: "20px !important",
              },
            },
          },
          size: 120,
        },
        "mrt-row-select": {
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
          size: 70,
        },
      };

  const defaultMuiToolbarAlertBannerProps = props.displayColumnDefOptions
    ? props.displayColumnDefOptions
    : {
        sx: {
          backgroundColor: "unset",
        },
      };

  const defaultMuiTableBodyRowProps = () => ({ sx: { backgroundColor: "inherit" } });

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableKeyboardShortcuts={enableKeyboardShortcuts}
        enableStickyHeader={enableStickyHeader}
        positionGlobalFilter={defaultPositionGlobalFilter}
        positionToolbarAlertBanner={defaultPositionToolbarAlertBanner}
        positionPagination={defaultPositionPagination}
        muiTableBodyProps={defaultMuiTableBodyProps}
        muiTableHeadProps={defaultMuiTableHeadProps}
        displayColumnDefOptions={defaultDisplayColumnDefOptions}
        muiDetailPanelProps={defaultMuiDetailPanelProps}
        muiSelectCheckboxProps={defaultMuiSelectCheckboxProps}
        muiSearchTextFieldProps={defaultMuiSearchTextFieldProps}
        muiToolbarAlertBannerChipProps={defaultMuiToolbarAlertBannerChipProps}
        muiTableContainerProps={defaultMuiTableContainerProps}
        muiToolbarAlertBannerProps={defaultMuiToolbarAlertBannerProps}
        muiTableBodyRowProps={defaultMuiTableBodyRowProps}
        muiLinearProgressProps={({ isTopToolbar }) => defaultMuiLinearProgressProps(isTopToolbar)}
        {...props}
      />
    </>
  );
};

export default CustomMaterialReactTable;
