import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const ErrorContext = createContext(undefined);

export const ErrorProvider = ({ children }) => {
  const [errorArray, setErrorArray] = useState([]);
  const [openGlobalErrorDialog, setOpenGlobalErrorDialog] = useState(false);

  // Function to update errors
  const addErrors = (errors) => {
    if (errors?.length > 0) {
      setErrorArray(errors);
      setOpenGlobalErrorDialog(true);
    }
  };

  const toggleGlobalErrorDialog = () => {
    setOpenGlobalErrorDialog(!openGlobalErrorDialog);
  };

  // Function to clear errors
  const clearErrors = () => {
    setErrorArray([]);
    setOpenGlobalErrorDialog(false);
  };

  return (
    <ErrorContext.Provider
      value={{ errorArray, openGlobalErrorDialog, toggleGlobalErrorDialog, addErrors, clearErrors }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

// **PropTypes Validation**
ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom Hook to Use Error Context
export const useErrorContext = () => useContext(ErrorContext);
