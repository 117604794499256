import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import CustomLoading from "components/CustomComponents/CustomLoading";
import GlobalErrorDialog from "components/CustomComponents/GlobalErrorDialog";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import brandWhite from "assets/images/sptech-logos/sptech-logo-200x78px.webp";

// Material Dashboard 2 PRO React routes
import routes from "routes";

import RoutesContainer from "RoutesContainer";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setSidenavColor } from "context";
import { setTransparentSidenav, setWhiteSidenav, setDarkMode } from "context";
import { useSnackbar } from "context/snackbarContext";
import { useErrorContext } from "context/errorContext";

export default function App() {
  const { errorArray, toggleGlobalErrorDialog } = useErrorContext();
  const { errorAlert, customAlert } = useSnackbar();
  const { isLoading, user, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const [controller, dispatch] = useMaterialUIController();
  const [userPerms, setUserPerms] = useState([]);
  const [mfaSessionId, setMFASessionId] = useState(null);
  const [isMFASessionIdLoading, setMFASessionIdLoading] = useState(true);
  const [otp, setOTP] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [secret, setSecret] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isMFAActive, setIsMFAActive] = useState(false);
  const [isMFAForced, setIsMFAForced] = useState(false);

  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const brandName = "SPTECH PTY LTD";
  let client = null;

  try {
    if (pathname.split("/").slice(1)[0] === "clients") client = pathname.split("/").slice(1)[1];
  } catch (error) {
    // failed to get client id
  }

  const handleDarkMode = () => {
    // Set Dark / Light Sidenav
    if (darkMode) {
      // white Sidenav
      Cookies.set("darkMode", "false", { secure: true, sameSite: "strict" });
      setWhiteSidenav(dispatch, true);
      // setTransparentSidenav(dispatch, false);
    } else {
      // dark Sidenav
      Cookies.set("darkMode", "true", { secure: true, sameSite: "strict" });
      setWhiteSidenav(dispatch, false);
      setTransparentSidenav(dispatch, false);
    }
    // Toggle Dark / Light Theme
    setDarkMode(dispatch, !darkMode);
  };

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Initial User login
  useEffect(() => {
    let mounted = true;

    const login = async () => {
      await loginWithRedirect();
    };

    const getAccesToken = async () => {
      const apiAccessToken = await getAccessTokenSilently();
      setAccessToken(apiAccessToken);
    };

    const fetchMFAStatus = async () => {
      // setLoading(true);
      // Check if MFA is active and if the user is forced to enable MFA
      try {
        // get MFA Session ID if available and prompt MFA setup/verification
        if (!Cookies.get("mfaSessionId")) {
          const response = await axios.get(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/active-and-forced`,
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
          const res = await axios.get(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
          if (res?.data?.mfaSessionId) {
            Cookies.set("mfaSessionId", res.data.mfaSessionId, {
              secure: true,
              sameSite: "strict",
            });
            setMFASessionId(res.data.mfaSessionId);
            setIsMFAActive(response.data.isMFAActive);
            setIsMFAForced(response.data.isMFAForced);
          } else {
            setIsMFAActive(response.data.isMFAActive);
            setIsMFAForced(response.data.isMFAForced);
          }
          if (!response.data.isMFAActive && response.data.isMFAForced && !imageUrl) {
            await setupTwoFactor();
          }
        }
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching MFA status", error);
        errorAlert(error);
      } finally {
        setMFASessionIdLoading(false);
      }
    };

    const resetMFACookie = async () => {
      const idTokenClaims = await getIdTokenClaims();
      const mfaSessionIdCookie = Cookies.get("mfaSessionId");
      if (idTokenClaims?.sid && mfaSessionIdCookie && idTokenClaims?.sid !== mfaSessionIdCookie) {
        try {
          Cookies.remove("mfaSessionId");
          setMFASessionId(null);
          await axios.post(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            { mfaSessionId: null },
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else if (idTokenClaims?.sid && idTokenClaims?.sid === mfaSessionIdCookie && !mfaSessionId) {
        setMFASessionId(mfaSessionIdCookie);
        setMFASessionIdLoading(false);
      }
      await fetchMFAStatus();
    };

    if (mounted && !isLoading) {
      if (!user) login();
      else if (!accessToken) getAccesToken();
      else {
        // removes the MFA session id cookie on new login and adds the new MFA session id cookie
        resetMFACookie();
      }
    }

    return () => (mounted = false);
  }, [isLoading, accessToken]);

  // Load user permissions
  useEffect(() => {
    const getUserPermissions = async () => {
      try {
        const idTokenClaims = await getIdTokenClaims();
        if (
          (idTokenClaims?.sid && mfaSessionId && idTokenClaims?.sid === mfaSessionId) ||
          (!isMFAActive && !isMFAForced)
        ) {
          const res = await axios.get(
            `${process.env.REACT_APP_APPSPACE_API}/admin/auth0-management-api/user-permissions`,
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
          setUserPerms(res.data.permissions ?? ["no_permissions"]);
          localStorage.setItem("userPerms", res.data.permissions?.toString() ?? "no_permissions");
        }
      } catch (error) {
        console.error(error);
        errorAlert(error);
      }
    };

    if (!isLoading && user && accessToken) {
      if (mfaSessionId || (!isMFAActive && !isMFAForced)) {
        const perms = localStorage.getItem("userPerms");
        if (typeof perms !== "string") {
          getUserPermissions();
        } else if (userPerms.length === 0) {
          setUserPerms(perms.split(",") ?? ["no_permissions"]);
        }
      }
    }
  }, [isLoading, accessToken, userPerms, mfaSessionId]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    const setTheme = () => {
      if (Cookies.get("darkMode") === "true" && !darkMode) {
        // set darkMode to true
        setWhiteSidenav(dispatch, false);
        setTransparentSidenav(dispatch, false);
        setDarkMode(dispatch, !darkMode);
      } else if (Cookies.get("darkMode") === "false" && darkMode) {
        // set darkMode to false
        setWhiteSidenav(dispatch, true);
        setDarkMode(dispatch, !darkMode);
      }
    };

    const setTitle = () => {
      try {
        const route = pathname.split("/").slice(1);
        let titlePart2 = route
          .slice(-1)[0]
          .split("-")
          .map((s) => s[0].toUpperCase() + s.substring(1));
        if (route[0].toLowerCase() === "clients") {
          const titlePart1 = route[1].toUpperCase();
          document.title = `${titlePart1} - ${titlePart2.join(" ")} - ${"SPTECH - IT Solutions"}`;
        } else {
          document.title = `${titlePart2.join(" ")} - ${"SPTECH - IT Solutions"}`;
        }
      } catch (error) {
        document.title = `${"SPTECH - IT Solutions"}`;
      }
    };

    if (!isLoading && user) {
      setTitle();
      setTheme();
      if (
        (isMFAActive && mfaSessionId !== null) ||
        (!isMFAActive && !isMFAForced && mfaSessionId !== null)
      ) {
        // remove redirectUri cookie as its not needed anymore
        Cookies.remove("redirectUri");
      }

      // Set sidenav color based on client ID in the URL pathname
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      switch (client) {
        case "spt01":
          setSidenavColor(dispatch, "primary");
          break;
        case "mfr01":
          setSidenavColor(dispatch, "primaryLight");
          break;
        case "rwa01":
          setSidenavColor(dispatch, "secondary");
          break;
        case "qex01":
          setSidenavColor(dispatch, "warning");
          break;
        default:
          setSidenavColor(dispatch, "primary");
          break;
      }
    }
  }, [isLoading, pathname]);

  const setupTwoFactor = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/setup`,
        {},
        {
          headers: { authorization: "Bearer " + accessToken, "Content-Type": "application/json" },
        }
      );
      if (res.data?.secret && res.data?.imageUrl) {
        setSecret(res.data.secret);
        setImageUrl(res.data.imageUrl);
      } else {
        setSecret("");
        setImageUrl("");
      }
    } catch (error) {
      errorAlert("Error setting up MFA!");
      console.error("Error setting up MFA", error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const response = await axios.post(
        `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/validate`,
        { otp },
        {
          headers: { authorization: "Bearer " + accessToken, "Content-Type": "application/json" },
        }
      );
      if (response.data?.verified) {
        Cookies.set("mfaSessionId", idTokenClaims?.sid, { secure: true, sameSite: "strict" });
        // Close dialog and allow access to app
        setMFASessionId(idTokenClaims?.sid);
        try {
          await axios.post(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            { mfaSessionId: idTokenClaims?.sid },
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        errorAlert("Invalid MFA token, please try again");
      }
    } catch (error) {
      console.error("Error verifying OTP", error);
      errorAlert(error);
    } finally {
      setMFASessionIdLoading(false);
    }
  };

  const verifyToken = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const res = await axios.post(
        `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/verify`,
        { otp, secret },
        {
          headers: { authorization: "Bearer " + accessToken, "Content-Type": "application/json" },
        }
      );
      if (res.data?.verified) {
        customAlert("MFA is now enabled", "success");
        Cookies.set("mfaSessionId", idTokenClaims?.sid, { secure: true, sameSite: "strict" });
        setMFASessionId(idTokenClaims?.sid);
        setIsMFAActive(true);
        try {
          await axios.post(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            { mfaSessionId: idTokenClaims?.sid },
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        errorAlert("Invalid MFA token, please try again");
      }
    } catch (error) {
      console.error("Error verifying token", error);
      errorAlert("Error verifying MFA token");
    } finally {
      setMFASessionIdLoading(false);
    }
  };

  const configsButton = (
    <MDBox
      sx={{
        position: "fixed",
        bottom: 20,
        right: 20,
        display: "flex",
        flexDirection: "column-reverse", // Ensure warning icon is on top
        alignItems: "center",
        gap: 1.5,
        zIndex: 1500,
      }}
    >
      {/* Floating Theme Changer - Below the Warning Icon */}
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="2.5rem"
        height="2.5rem"
        bgColor={darkMode ? "white" : "dark"}
        shadow="sm"
        borderRadius="50%"
        position="relative"
        color={darkMode ? "dark" : "white"}
        sx={{ cursor: "pointer" }}
        onClick={handleDarkMode}
      >
        <Icon fontSize="medium" color="inherit">
          {darkMode ? "light_mode" : "dark_mode"}
        </Icon>
      </MDBox>
      {/* Floating Warning Icon - Always Visible */}
      {errorArray.length > 0 && (
        <MDBox>
          {/* <Icon
            fontSize="small"
            sx={{
              position: "absolute",
              top: "-14px", // Adjust as needed
              right: "-7px", // Adjust as needed
              color: "error.main",
              borderRadius: "50%",
              // padding: "2px",
              cursor: "pointer",
              // boxShadow: 1, // Adds subtle shadow
            }}
            onClick={clearErrors} // Clear errors on click
          >
            close
          </Icon> */}
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="2.5rem"
            height="2.5rem"
            bgColor="error"
            shadow="sm"
            borderRadius="50%"
            position="relative"
            color="white"
            sx={{ cursor: "pointer" }}
            onClick={toggleGlobalErrorDialog}
          >
            <Icon fontSize="medium">error_outline</Icon>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {/* Global Error Dialog */}
      <GlobalErrorDialog />
      <CssBaseline />
      {!isMFAActive && isMFAForced ? (
        <Dialog
          open={true}
          slotProps={{
            paper: {
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                verifyToken();
              },
            },
          }}
        >
          <DialogTitle>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid>
                <MDTypography color="dark" variant="h5">
                  {`Setup Two-Factor Authentication`}
                </MDTypography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
              <Grid size={{ sm: 12 }} sx={{ textAlign: "center" }}>
                <MDTypography color="dark" variant="body" fontSize="16px" fontWeight="regular">
                  {`Please set up Two-Factor Authentication to continue using the app.`}
                </MDTypography>
              </Grid>
              <Grid size={{ sm: 12 }} sx={{ textAlign: "center" }}>
                <br />
                <p>Scan this QR code with your Authenticator app</p>
              </Grid>
              <Grid size={{ sm: 12 }} sx={{ textAlign: "center" }}>
                {imageUrl ? (
                  <img src={imageUrl} alt="QR Code" />
                ) : (
                  <MDTypography color="dark" variant="body" fontSize="16px" fontWeight="regular">
                    {`Loading QR Code...`}
                  </MDTypography>
                )}
              </Grid>
              <Grid size={{ xs: 12, sm: 5 }} sx={{ textAlign: "center" }}>
                <TextField
                  autoFocus
                  sx={{ width: "200px" }}
                  margin="dense"
                  variant="outlined"
                  name={`Authenticator-otp-verification`}
                  label="Enter the code from the app"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  slotProps={{ htmlInput: { type: "text" } }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }} sx={{ textAlign: "center" }}>
                <MDButton sx={{ my: "16px" }} variant="gradient" color="success" type="submit">
                  Verify Code
                </MDButton>
              </Grid>
              <Grid size={{ sm: 12 }} sx={{ textAlign: "center", mt: "32px" }}>
                <MDTypography variant="button" sx={{ fontSize: "12px" }}>
                  {`In case of any issues please reach out to our 🕵️‍♂️ digital superheroes at `}
                  <a href="mailto:webapps@sptech.com.au">webapps@sptech.com.au</a>
                </MDTypography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : isMFAActive && mfaSessionId === null ? (
        <Dialog
          open={true}
          slotProps={{
            paper: {
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                handleVerifyOtp();
              },
            },
          }}
        >
          <DialogTitle>Verify OTP</DialogTitle>
          <DialogContent>
            {/* <br /> */}
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              variant="outlined"
              name={`Authenticator-otp verification`}
              label="One-Time Password"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              slotProps={{ htmlInput: { type: "text" } }}
            />
          </DialogContent>
          <DialogActions>
            <MDButton size="small" variant="gradient" color="success" type="submit">
              Verify
            </MDButton>
          </DialogActions>
        </Dialog>
      ) : (isMFAActive && mfaSessionId !== null && userPerms.length > 0) ||
        (!isMFASessionIdLoading && !isMFAActive && !isMFAForced && userPerms.length > 0) ? (
        <>
          {/* user.email.includes("dashboard") */}
          {user && layout === "dashboard" && !user.email.includes("dashboard") && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandWhite : brandWhite}
                brandName={brandName}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator /> */}
              {configsButton}
            </>
          )}
          <RoutesContainer userPerms={userPerms} />
        </>
      ) : (
        <CustomLoading />
      )}
    </ThemeProvider>
  );
}
