import React, { Suspense, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import CustomLoading from "components/CustomComponents/CustomLoading";
import protectedRoutes, { publicRoutes, otherProtectedRoutes } from "routes";

// Helper to flatten routes
const flattenRoutes = (routes, parentPath = "") => {
  const result = [];

  for (const route of routes) {
    const currentPath = route.route ?? `${parentPath}/${route.key}`;
    if (route.collapse) {
      // Push as non-navigable container (for redirect)
      result.push({
        ...route,
        route: currentPath.replace(/\/+/g, "/"), // clean slashes
        isNavigable: false,
      });
      result.push(...flattenRoutes(route.collapse, currentPath));
    } else if (route.route && route.component) {
      result.push({
        ...route,
        route: route.route,
        isNavigable: true,
      });
    }
  }

  return result;
};

const RoutesContainer = ({ userPerms = [] }) => {
  // const [userHomepage, setUserHomepage] = useState(process.env.REACT_APP_HOMEPAGE);

  /**
   * On every page refresh, auth0 is called to verify user login
   * A cookie "redirectUri" is created with the current path and user is redirected to auth0 /authorize
   * auth0 will return user to the origin url (ex: https://localhost/)
   * use the stored cookie to navigate to the path and after page load delete cookie
   */
  const redirectUri = Cookies.get("redirectUri");

  const allFlattenedRoutes = useMemo(
    () => [
      ...flattenRoutes(protectedRoutes),
      ...flattenRoutes(otherProtectedRoutes),
      ...flattenRoutes(publicRoutes),
    ],
    []
  );

  // const getUserHomepage = (allRoutes, userPermissions) => {
  //   let homepage = process.env.REACT_APP_HOMEPAGE;
  //   for (let route of allRoutes) {
  //     if (homepage !== process.env.REACT_APP_HOMEPAGE) return homepage;
  //     else if (route.route && userPermissions != null && Array.isArray(userPermissions)) {
  //       if (route.permissions) {
  //         try {
  //           const allowedPermissions = route.permissions.filter((p) => userPermissions.includes(p));
  //           if (allowedPermissions.length > 0) homepage = route.route;
  //         } catch (error) {
  //           // throws error if route.permissions is not an array
  //           console.error(error);
  //         }
  //       } else homepage = route.route;
  //     } else if (route.collapse) homepage = getUserHomepage(route.collapse, userPermissions);
  //   }
  //   return homepage;
  // };

  const getUserHomepage = (routesList, permissions) => {
    for (const route of routesList) {
      if (
        route.isNavigable &&
        (!route.permissions || route.permissions.some((p) => permissions.includes(p)))
      ) {
        return route.route;
      }
    }
    return process.env.REACT_APP_HOMEPAGE;
  };

  // const getAuthorizedRoutes = (allRoutes, userPermissions) => {
  //   return allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getAuthorizedRoutes(route.collapse, userPermissions);
  //     } else if (
  //       userPermissions != null &&
  //       Array.isArray(userPermissions) &&
  //       route.route &&
  //       route.permissions
  //     ) {
  //       const allowAccess = route.permissions.filter((p) => userPermissions.includes(p));
  //       if (allowAccess.length > 0) {
  //         if (userHomepage === process.env.REACT_APP_HOMEPAGE) {
  //           setUserHomepage(route.route);
  //         }
  //         return <Route exact path={route.route} element={route.component} key={route.key} />;
  //       }
  //     } else if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     } else return null;
  //   });
  // };

  const getAuthorizedRoutes = (routesList, permissions) => {
    return routesList
      .filter(
        (route) =>
          route.isNavigable &&
          (!route.permissions || route.permissions.some((p) => permissions.includes(p)))
      )
      .map((route) => <Route exact path={route.route} element={route.component} key={route.key} />);
  };

  const getRedirectsForNonNavigable = (routesList) => {
    const redirects = [];

    const navigableMap = new Map();
    routesList.forEach((route) => {
      if (route.isNavigable) {
        const parent = route.route.split("/").slice(0, -1).join("/");
        if (!navigableMap.has(parent)) {
          navigableMap.set(parent, route.route); // first child route as default redirect
        }
      }
    });

    routesList.forEach((route) => {
      if (!route.isNavigable && route.route && navigableMap.has(route.route)) {
        redirects.push(
          <Route
            path={route.route}
            element={<Navigate to={navigableMap.get(route.route)} />}
            key={`redirect-${route.key}`}
          />
        );
      }
    });

    return redirects;
  };
  const authorizedProtectedRoutes = getAuthorizedRoutes(allFlattenedRoutes, userPerms ?? []);
  const redirectsForContainers = getRedirectsForNonNavigable(allFlattenedRoutes);
  const homepageRoute = getUserHomepage(allFlattenedRoutes, userPerms ?? []);

  // const getGeneralRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getGeneralRoutes(route.collapse);
  //     } else if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     } else return null;
  //   });

  return (
    <Suspense fallback={<CustomLoading />}>
      <Routes>
        {/* Public Routes */}
        {getAuthorizedRoutes(flattenRoutes(publicRoutes), [])}
        {/* Protected Routes */}
        {authorizedProtectedRoutes}

        {/* Fake path redirects */}
        {redirectsForContainers}
        {/* Routes that don't need any permissions and is available to all logged-in users */}
        {/* {getGeneralRoutes(publicRoutes)} */}
        {/* Routes mainly containing query params or routes that dont need to be displayed in the sidenav */}
        {/* {getAuthorizedRoutes(otherProtectedRoutes, userPerms ?? [])} */}
        {/* Routes that must be displayed in the sidenav */}
        {/* {getAuthorizedRoutes(protectedRoutes, userPerms ?? [])} */}
        {/* Redirects based on cookie or homepage */}
        {redirectUri ? (
          <>
            <Route exact path="/" element={<Navigate to={redirectUri} />} />
            <Route path="/*" element={<Navigate to={`/page-not-found`} />} />
          </>
        ) : (
          <>
            <Route exact path="/" element={<Navigate to={homepageRoute} />} />
            <Route path="/*" element={<Navigate to={`/page-not-found`} />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

RoutesContainer.propTypes = {
  userPerms: PropTypes.array,
};

export default RoutesContainer;
