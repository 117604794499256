/* eslint-disable react/prop-types */
import React from "react";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Minimize } from "@mui/icons-material";

import CustomMaterialReactTable from "components/CustomComponents/CustomMaterialReactTable";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import { useErrorContext } from "context/errorContext"; // Import the error context

import { useTheme } from "@mui/material/styles";

const GlobalErrorDialog = () => {
  const theme = useTheme();
  const { errorArray, openGlobalErrorDialog, toggleGlobalErrorDialog, clearErrors } =
    useErrorContext();

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id", // Accessor is the "key" in the data
        header: "ID",
        Cell: ({ row }) => row.index + 1, // Display index + 1 for ID
        // size: 50,
      },
      {
        accessorKey: "message",
        header: "Error Message",
        // size: 300,
      },
      {
        accessorKey: "row",
        header: "Record",
        // size: 200,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue();

          if (!cellValue || typeof cellValue !== "object") {
            return (
              <MDTypography variant="caption" color="textSecondary">
                No Data
              </MDTypography>
            );
          }
          return (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {Object.entries(cellValue).map(([key, value]) => (
                <MDBox key={key} display="flex">
                  <MDTypography variant="caption" fontWeight="bold" sx={{ mr: 0.5 }}>
                    {key}:
                  </MDTypography>
                  <MDTypography variant="caption">{value ?? "N/A"}</MDTypography>
                </MDBox>
              ))}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Drawer
      anchor="bottom"
      open={openGlobalErrorDialog}
      onClose={toggleGlobalErrorDialog}
      sx={{ zIndex: "2000" }}
      slotProps={{
        paper: {
          style: { width: "auto", height: "auto", padding: "16px" },
        },
      }}
    >
      {/* Title & Action Buttons */}
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <MDTypography color="error" variant="h6">
          Errors Detected
        </MDTypography>

        {/* Action Icons - Minimize & Close */}
        <MDBox>
          <IconButton color="error" onClick={toggleGlobalErrorDialog}>
            <Minimize sx={{ mb: "12px" }} fontSize="medium" />
          </IconButton>
          <IconButton color="error" onClick={clearErrors}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </MDBox>
      </MDBox>

      {/* Error Table */}
      <CustomMaterialReactTable
        columns={columns}
        data={errorArray}
        getRowId={(row, relativeIndex) => relativeIndex} // Unique ID for each row based on its index
        enableGrouping={false}
        enableRowVirtualization
        enableColumnFilters={false}
        enableHiding={false}
        enableEditing={false}
        muiPaginationProps={{ rowsPerPageOptions: [10, 25, 50, 100] }}
        initialState={{
          showGlobalFilter: true,
          density: "compact",
          columnOrder: [...columns.map((c) => c.accessorKey)],
          pagination: { pageIndex: 0, pageSize: 50 },
        }}
        defaultColumn={{
          muiTableHeadCellProps: {
            sx: { color: theme.palette.error.main },
          },
        }}
        muiTableContainerProps={{
          sx: { maxHeight: "60vh", borderRadius: "0" },
        }}
      />
    </Drawer>
  );
};

export default GlobalErrorDialog;
