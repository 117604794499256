import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router";

import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import SnackbarProvider from "context/snackbarContext";
import Auth0ProviderContext from "context/auth0ProviderContext";
import { ErrorProvider } from "context/errorContext";

const container = document.getElementById("root");
const root = createRoot(container);
/**
 * , {
  onUncaughtError: (error, errorInfo) => {
    // Send to Sentry, LogRocket, Datadog, etc.
    console.error("Uncaught React Error:", error, errorInfo);
  },
}
 */

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Auth0ProviderContext>
          <SnackbarProvider>
            <ErrorProvider>
              <App />
            </ErrorProvider>
          </SnackbarProvider>
        </Auth0ProviderContext>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>
);
