// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

const globals = {
  /*
   * Container style
   */
  "::-webkit-scrollbar": {
    overflow: "hidden",
    overflowAnchor: "none",
    msOverflowStyle: "none",
    touchAction: "auto",
    width: "11px",
    height: "11px",
    transition: "background-color .2s linear, width .2s ease-in-out",
    webkitTransition: "background-color .2s linear, width .2s ease-in-out",
    msTouchAction: "auto",
  },
  /* Scrollbar Rail/Track Styles */
  "::-webkit-scrollbar-track": {
    display: "none",
    background: "transparent",
    opacity: 0,
  },
  "::-webkit-scrollbar-track:horizontal:hover": {
    display: "block",
    backgroundColor: "#eee",
    opacity: 1,
  },
  "::-webkit-scrollbar-track:horizontal:focus": {
    display: "block",
    backgroundColor: "#eee",
    opacity: 1,
  },
  "::-webkit-scrollbar-track:vertical:hover": {
    display: "block",
    backgroundColor: "#eee",
    opacity: 1,
  },
  "::-webkit-scrollbar-track:vertical:focus": {
    display: "block",
    backgroundColor: "#eee",
    opacity: 1,
  },
  /* Scrollbar Thumb Styles */
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#aaaaaa",
    opacity: 0,
    borderRadius: "7px",
    transition: "background-color .2s linear, width .2s ease-in-out",
    webkitTransition: "background-color .2s linear, width .2s ease-in-out",
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: "#999999",
    borderRadius: "7px",
  },

  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
    color: `${dark.main}`,
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main}`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main}`,
  },
  ".pac-container": {
    zIndex: `1301 !important`,
  },
};

export default globals;
